@media only screen and (max-width: 767px) {
  .mobileNavbar {
    display: flex;
    /* width: 62vw; */
    width: 70vw;
    justify-content: space-between;
  }
  .mobileNavbarHeader {
    margin: 10px;
    color: white;
    font-size: 20px;
    font-family: "Recoleta", sans-serif;
    font-weight: bold;
  }
  .mobileDrawerHeader {
    font-family: "Recoleta", sans-serif;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
    font-weight: 700;
    color: #ffffff;
    margin: 0 auto;
    width: 60%;
  }
  .mobileDrawerLinksContainer {
  }
  .mobileDrawerLink {
    margin-top: 20px;
    font-family: "Monsterrat", sans-serif;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 1.4px;
    font-weight: 700;
    color: #ffffff;
  }
  .mobileMenuImg {
    background-image: url("../../assets/images/menu.png");
    background-size: cover;
    margin: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mobileNavbar {
    display: flex;
    width: 60%;
    justify-content: space-between;
  }
  .mobileNavbarHeader {
    margin: 10px;
    color: white;
    font-size: 20px;
    font-family: "Recoleta", sans-serif;
    font-weight: bold;
  }
  .mobileDrawerHeader {
    font-family: "Recoleta", sans-serif;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
    font-weight: 700;
    color: #ffffff;
    margin: 0 auto;
    width: 60%;
  }
  .mobileDrawerLinksContainer {
  }
  .mobileDrawerLink {
    margin-top: 20px;
    font-family: "Monsterrat", sans-serif;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 1.4px;
    font-weight: 700;
    color: #ffffff;
  }
  .mobileMenuImg {
    background-image: url("../../assets/images/menu.png");
    background-size: cover;
    margin: 10px;
  }  
}
@media only screen and (min-width: 1024px) {
  .mobileNavbar{display: none;}
}
