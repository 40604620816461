
/* Smartphones (portrait and landscape) */
@media only screen and (max-width: 767px) {
  .navbarMainContainer {
    display: none;
  }
}
.navbarOption {
  display: flex;
  align-items: center;
  margin: 15px 10px;
  /* color: white; */
  color: #e66304;
  font-size: 16px;
  font-family: "Recoleta", sans-serif;
}
.navbarOption:hover {
  cursor: pointer;
  /* color: #06d900; */
  /* color: #e66304; */
  color: white;
  transition: color 0.3s;
  .dot {
    /* background-color: #06d900; */
    /* background-color: #e66304; */
    background-color: white;
    transition: background-color 0.3s;
  }
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* background-color: #ccc; */
  background-color: #e66304;
  margin: 0 10px;
}
.active {
  /* color: #e66304; */
  color: white;
  transition: color 0.3s;
  .dot {
    /* background-color: #06d900; */
    /* background-color: #e66304; */
    background-color: white;
    transition: background-color 0.3s;
  }
}

/* Tablets (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .navbarMainContainer {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .navbarMainContainer {
    display: flex;
    justify-content: space-around;
    font-family: "Monsterrat", sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.4px;
    align-items: center;
    background-color: black;
    padding: 20px 0px;
  }
  .navbarContainer1 {
    display: flex;
    width: 10%;
  }
  .navbarContainer2 {
    display: flex;
    justify-content: center;
    color: white;
    width: 50%;
  }
  .navbarContainer3 {
    display: flex;
    width: 8%;
    justify-content: space-around;
  }
  .navbarContainer1Img {
    width: 28vw;
    cursor: pointer;
  }
  .searchBtn {
    background-image: url("../../assets/images/search.png");
    width: 25px;
    height: 25px;
    background-size: cover;
    filter: invert(0.85);
    border: none;
    cursor: pointer;
  }
  .crossBtn {
    background-image: url("../../assets/images/cross.png");
    width: 25px;
    height: 25px;
    background-size: cover;
    border: none;
    cursor: pointer;
    background-color: inherit;
  }

  .navbarOption {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  .navbarOption:hover {
    cursor: pointer;
    /* color: #06d900; */
    /* color: #e66304; */
    color: white;
    transition: color 0.3s;
    .dot {
      /* background-color: #06d900; */
      /* background-color: #e66304; */
      background-color: white;
      transition: background-color 0.3s;
    }
  }

  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    /* background-color: #ccc; */
    background-color: #e66304;
    margin: 0 10px;
  }
  .active {
    /* color: #06d900; */
    /* color: #e66304; */
    color: white;
    transition: color 0.3s;
    .dot {
      /* background-color: #06d900; */
      /* background-color: #e66304; */
      background-color: white;
      transition: background-color 0.3s;
    }
  }
}
