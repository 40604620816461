.customCarousel {
  margin-top: 40px;
}
.ant-carousel .slick-dots li button {
  width: 25%;
  height: 5px;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .customCarousel {
  }
}
