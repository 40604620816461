.availablityMainContainer {
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.availablityContainer {
  width: 55%;
  text-align: center;
  cursor: pointer;
}
.availabilityText1 {
  font-family: "Recoleta", sans-serif;
  font-size: 52px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 70px;
  color: #ffffff;
}
.availabilityText2 {
  font-family: "Recoleta", sans-serif;
  font-size: 52px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 70px;
  /* color: #06d900; */
  color: #e66304;
  text-decoration: underline;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .availabilityText1 {
    font-size: 5vw;
    line-height: 3vh;
  }
  .availabilityText2 {
    font-size: 5vw;
    line-height: 3vh;
  }
  .availablityContainer {
    width: 60%;
  }
}
