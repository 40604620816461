/* BackToTopButton.css */
.backToTopButton {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 100px;
  background-color: #2e2e2e;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  .backToTopButton:hover{
    filter: invert();
  }
  
  .backToTopButton.visible {
    display: block;
    opacity: 1;
  }
  