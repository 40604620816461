.servicesPageMainContainer {}

.servicesContent {
  display: flex;
}

.servicesHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 150px 0 0 0; */
  background-image: url("../../assets/images/servicesBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
}

.servicesHeader {
  /* font-family: "Arimo", sans-serif; */
  font-family: "Recoleta", sans-serif;
  font-size: 70px;
  line-height: 60px;
  letter-spacing: normal;
  font-weight: 700;
  color: #e66304;
}

.servicesLinkContainer {
  margin-top: 20px;
}

.servicesLinkText1 {
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1.4px;
  font-weight: 700;
  color: #888888;
  transition: color 0.5s ease;
}

.servicesLinkText1:hover {
  cursor: pointer;
  color: white;
  transition: color 0.5s ease;
}

.servicesLinkText2 {
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1.4px;
  font-weight: 700;
  color: #ffffff;
}

.servicesContentSection1 {
  width: 40%;
  padding: 50px;
}

.servicesContentSection1Text1 {
  margin: 40px 0 10px 0;
  font-family: "Recoleta", sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #888888;
}

.servicesContentSection1Text2 {
  font-family: "Recoleta", sans-serif;
  font-size: 38px;
  line-height: 49px;
  letter-spacing: normal;
  font-weight: 700;
  color: #ffffff;
}

.servicesContentSection1Text3a {
  margin-top: 40px;
  font-family: "Recoleta", sans-serif;
  font-size: 38px;
  line-height: 49px;
  letter-spacing: normal;
  font-weight: 700;
  /* color: #06d900; */
  color: #e66304;
}

.servicesContentSection1Text3b {
  margin-top: 40px;
  font-family: "Recoleta", sans-serif;
  font-size: 38px;
  line-height: 49px;
  letter-spacing: normal;
  font-weight: 700;
  color: #ffffff;
}

.templatesMainContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.templateContainer {
  display: flex;
}

.template {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}

.templateNumber {
  font-family: "Monsterrat", sans-serif;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 500;
  color: #888888;
}

.templateDivider {
  background-color: #d0d0d0;
  height: 1px;
  width: 90%;
  margin-top: 20px;
}

.templateHeader {
  margin-top: 10px;
  font-family: "Recoleta", sans-serif;
  font-size: 26px;
  line-height: 52px;
  letter-spacing: 0.2px;
  font-weight: 500;
  color: #e66304;
}

.templateText {
  margin-top: 10px;
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 400;
  color: #d0d0d0;
  width: 90%;
}

.template:hover {
  transition: color 0.5s ease;

  .templateNumber {
    /* color: #06d900; */
    color: white;
  }

  .templateHeader {
    /* color: #06d900; */
    color: white;
  }

  .templateDivider {
    background-color: #ffc01f;
  }
}

@media only screen and (max-width: 767px) {
  .servicesContent {
    flex-direction: column;
  }

  .servicesContentSection1 {
    width: 100%;
    padding: 20px;

  }

  .servicesTextContainer {
    display: flex;
    flex-direction: column;

  }

  .servicesContentSection1Text2 {
    /* margin: 0; */
    margin: 0 0 0 3vw;
    /* font-size: 6vw;
    width: 100%; */
  }

  .servicesContentSection1Text2Mobile {
    margin: 0 0 0 38vw;
  }

  .servicesContentSection1Text3a {
    margin: 0 0 0 20vw;
    /* font-size: 6vw;
    width: 100%; */
  }

  .servicesContentSection1Text3b {
    /* margin: 0; */
    /* margin: 0 0 0 6vw; */
    /* font-size: 6vw;
    width: 100%; */
  }

  .templateContainer {
    flex-direction: column;
    
  }
  .templatesMainContainer{
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .servicesContentSection1 {
    display: none;
  }

  .templatesMainContainer {
    width: 90%;
    margin: 40px auto;
  }

  .templateContainer {
    display: flex;
    width: 100%;
  }

  .template {
    width: 100%;
    padding: 5px;
  }

  .templateHeader {
    margin-top: 10px;
    font-family: "Recoleta", sans-serif;
    font-size: 22px;
    line-height: 52px;
    letter-spacing: 0.2px;
    font-weight: 500;
    color: #ffffff;
  }
}