.carouselContainer{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 50px;
}
.carouselCard{
    margin: 20px;
}
.carouselImgStyling{
    background-repeat: none;
    width: 90%;
    height: 60vh;

}
.carouselText {
    font-family: "Recoleta", sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 46.8px;
    color: #ffffff;
  }
.carouselNumber {
    font-family: "Recoleta", sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 46.8px;
    color: #ffffff;
  }
