#preloader-wrapper {
    background-color: #191919;
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
  .preloader-container {
    position: relative;
    width: 82px;
    height: 82px;
    left: 50%;
    top: 50%;
    margin-top: -41px;
    margin-left: -41px;
  }
  .preloader-container > div {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 1px;
    float: left;
    /* background: #06d900; */
    background: #e66304;
    transform: scale(0);
    transform-origin: center center;
    animation: loader 2s infinite linear;
  }
  .preloader-container > div:nth-of-type(1), .preloader-container > div:nth-of-type(5), .preloader-container > div:nth-of-type(9) {
    animation-delay: 0.4s;
  }
  .preloader-container > div:nth-of-type(4), .preloader-container > div:nth-of-type(8) {
    animation-delay: 0.2s;
  }
  .preloader-container > div:nth-of-type(2), .preloader-container > div:nth-of-type(6) {
    animation-delay: 0.6s;
  }
  .preloader-container > div:nth-of-type(3) {
    animation-delay: 0.8s;
  }
  
  @keyframes loader {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    80% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }