.contactBackgroundImg {
  background-image: url("../../assets/images/contactBackground.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.contactMainContainer {
  /* background-color: #181818; */
}
.contactHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 150px 0 0 0;
}
.contactHeader {
  font-family: "Recoleta";
  font-size: 70px;
  line-height: 60px;
  letter-spacing: normal;
  font-weight: 700;
  color: #e66304;
}
.contactLinkContainer {
  margin-top: 20px;
}
.contactLinkText1 {
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1.4px;
  font-weight: 700;
  color: #888888;
  transition: color 0.5s ease;
}
.contactLinkText1:hover {
  cursor: pointer;
  color: white;
  transition: color 0.5s ease;
}
.contactLinkText2 {
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1.4px;
  font-weight: 700;
  color: #ffffff;
}
.contactContainer {
  display: flex;
  background-color: transparent;
  margin: 100px auto;
  width: 90%;
}
.contactContainer1 {
  width: 40%;
  padding: 0px 62px;
}
.contactContainer1Text1 {
  margin-top: 10px;
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #888888;
}
.contactContainer1Text2 {
  width: 90%;
  margin-top: 20px;
  font-family: "Monsterrat", sans-serif;
  font-size: 38px;
  line-height: 49px;
  letter-spacing: normal;
  font-weight: 700;
  color: #ffffff;
}
.contactContainer1Text3 {
  margin-top: 15px;
  font-family: "Monsterrat", sans-serif;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 400;
  color: #c4c4c4;
}
.contactContainer1Text4 {
  margin-top: 20px;
  font-family: "Monsterrat", sans-serif;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
}
.contactContainer2 {
  width: 55%;
}

.contactFormContainer {
  background-color: #232323;
  padding: 40px 40px;
}
.contactFormText1 {
  font-family: "Monsterrat", sans-serif;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: normal;
  font-weight: 700;
  color: #ffffff;
}
.contactFormText2 {
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: normal;
  font-weight: 400;
  color: #d0d0d0;
  margin-top: 15px;
}
.contactFormInputMainContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}
.contactFormInputContainer {
}
.contactFormInputContainer:hover {
  .contactFormDivider {
    transition: background-color 0.3s ease;
    /* background-color: #06d900; */
    background-color: #e66304;
  }
}
.contactFormInput1 {
  background-color: #232323;
  border: none;
  outline: none;
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
}
.contactFormInput1::placeholder {
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
}
.contactFormInput2 {
}
.contactFormInputText1 {
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
}
.contactFormInputText2 {
}
.contactFormDivider {
  height: 1px;
  width: 20vw;
  background-color: white;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.contactFormTextArea {
  background-color: #232323;
  width: 100%;
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
  margin-top: 20px;
  border: 1px solid white;
  height: 150px;
  padding: 10px;
}
.contactFormTextArea:hover {
  transition: border 0.3s ease;
  /* border: 1px solid #06d900; */
  border: 1px solid #e66304;
}
.contactFormTextArea::placeholder {
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
}
.contactFormTextAreaCounting {
  font-family: "Monsterrat", sans-serif;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
  width: 95%;
  text-align: right;
}
.contactFormButton {
  margin-top: 50px;
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #010000;
  background-color: #e66304;
  border: none;
  padding: 5px 10px;
}
.contactFormButton:hover {
  color: white;
  background-color: black;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.errorMessage {
  color: red;
}

@media only screen and (max-width: 767px) {
  .contactHeader {
    font-family: "Recoleta", sans-serif;
    font-size: 10vw;
    line-height: 20px;
    letter-spacing: normal;
    font-weight: 700;
    color: #e66304;
  }
  .contactContainer {
    flex-direction: column;
  }
  .contactContainer1 {
    width: 100%;
    padding: 5vw;
  }
  .contactContainer1Text2 {
    font-size: 7vw;
  }
  .contactFormContainer {
    width: 80vw;
    margin: 40px 6vw 0 6vw;
    padding: 8vw;
    /* height: 200vw; */
  }
  .contactFormInputMainContainer {
    flex-direction: column;
    margin: 50px 0 0 0;
  }

  .contactFormText1 {
    font-family: "Monsterrat", sans-serif;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: normal;
    font-weight: 700;
    color: #ffffff;
  }
  .contactFormText2 {
    width: 70vw;
    letter-spacing: normal;
    font-weight: 400;
    color: #d0d0d0;
    margin-top: 5px;
  }
  .contactFormInputContainer {
    margin: 20px 0px;
    width: 68vw;
  }
  .contactFormInput1 {
    width: 80%;
  }
  .contactFormDivider {
    height: 1px;
    background-color: white;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  .contactFormTextArea {
    width: 68vw;
  }
  .contactFormButton {
    width: 68vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .contactHeader {
    font-family: "Recoleta", sans-serif;
    font-size: 6vw;
    line-height: 20px;
    letter-spacing: normal;
    font-weight: 700;
    color: #e66304;
  }
  .contactContainer {
    flex-direction: column;
  }
  .contactContainer1 {
    width: 90%;
    padding: 5vw;
    margin: 0 auto;
  }
  .contactContainer1Text2 {
    font-size: 5vw;
  }
  .contactContainer2 {
    width: 55%;
  }

  .contactFormContainer {
    width: 80vw;
    margin: 40px 6vw 0 6vw;
    padding: 8vw;
    /* height: 200vw; */
  }
  .contactFormInputMainContainer {
    width: 100%;
  }

  .contactFormText1 {
    font-family: "Monsterrat", sans-serif;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: normal;
    font-weight: 700;
    color: #ffffff;
  }
  .contactFormText2 {
    width: 70vw;
    letter-spacing: normal;
    font-weight: 400;
    color: #d0d0d0;
    margin-top: 5px;
  }
  .contactFormInputContainer {
    width: 100vw;
    margin-left: 20px;
  }
  .contactFormInput1 {
    width: 100%;
  }
  .contactFormDivider {
    height: 1px;
    background-color: white;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  .contactFormTextArea {
    width: 68vw;
  }
  .contactFormButton {
    width: 18vw;
  }
}
