.getInTouchButton {
  /* position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-weight: 700;
  border-radius: 25px; */
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #e66304;
  color: white;
  border: 2px solid #e66304;
  cursor: pointer;
  overflow: hidden;
  font-weight: 700;
  border-radius: 25px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.overlay::before {
  /* content: "";
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background-color: #e66304;
  width: 35%; 
  transition: right 0.3s ease-out, width 0.3s ease-out;
  opacity: 0.5;
  border-radius: 20px;
  height: 40px;
  width: 40px; */
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background-color: #e66304;
  width: 35%;
  transition: right 0.3s ease-out, width 0.3s ease-out;
  opacity: 0.7;
  border-radius: 50px;
  height: 50px;
  width: 50px;
}

.getInTouchButton:hover .overlay::before {
  right: 0;
  width: 100%; /* Spread to cover the entire width on hover */
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .getInTouchButton {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #e66304;
    color: white;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-weight: 700;
    border-radius: 25px;
  }

  .overlay::before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background-color: #e66304;
    width: 35%;
    transition: right 0.3s ease-out, width 0.3s ease-out;
    opacity: 0.7;
    border-radius: 50px;
    height: 35px;
    width: 35px;
  }

  .getInTouchButton:hover .overlay::before {
    right: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .getInTouchButton {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    background-color: #e66304;
    color: white;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-weight: 700;
    border-radius: 15px;
    margin-bottom: 20px;
  }

  .overlay::before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background-color: #e66304;
    width: 35%;
    transition: right 0.3s ease-out, width 0.3s ease-out;
    opacity: 0.7;
    border-radius: 50px;
    height: 30px;
    width: 30px;
  }

  .getInTouchButton:hover .overlay::before {
    right: 0;
    width: 100%;
  }
}
