.movingHeadline {
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  font-family: 'Recoleta',sans-serif;
}

.movingHeadline div {
  display: flex;
  width: 100%;
  animation: moveLeft 30s linear infinite;
}

@keyframes moveLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.movingHeadline p {
  margin: 0;
}

.movingHeadline img {
  max-width: 100%;
}
.movingHeadlineSection2bPara {
  font-size: 130px;
  font-weight: 700;
  color: #161616;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}
.movingHeadlineSection2bImgStyling {
  width: 100px;
  height: 100px;
}
.movingHeadlineSection2aPara {
  font-size: 130px;
  font-weight: 700;
  color: white;
}

.headlineContainer {
  display: flex;
  align-items: center;
}
.headlineContainer > * {
  margin: 0 50px;
}
@media only screen and (max-width: 767px) {
  
  .movingHeadlineSection2aPara {
    font-size: 10vw;
  }
  .movingHeadlineSection2bPara {
    font-size: 10vw;
  }
  .movingHeadline img {
    width: 8vw;
    height: 5vh;
  }
}
