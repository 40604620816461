@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"),
    url("../../assets/fonts/recoleta-font/RecoletaAlt-Bold.woff2")
      /* url("./assets/fonts/recoleta-font/RecoletaAlt-Bold.woff2") */
      format("opentype");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.aboutMainContainer {
  /* background-image: url("../../assets/images/servicesBackground.png");
  background-repeat: no-repeat;
  background-size: contain; */
}
.aboutHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/testBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
}
.aboutHeader {
  font-family: "Recoleta", sans-serif;
  font-size: 70px;
  line-height: 60px;
  letter-spacing: normal;
  font-weight: 700;
  color: #e66304;
}
.aboutLinkContainer {
  margin-top: 20px;
}
.aboutLinkText1 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1.4px;
  font-weight: 700;
  color: #888888;
  transition: color 0.5s ease;
}
.aboutLinkText1:hover {
  cursor: pointer;
  color: white;
  transition: color 0.5s ease;
}
.aboutLinkText2 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 1.4px;
  font-weight: 700;
  color: #ffffff;
}
.aboutContentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.aboutContentContainer {
  display: flex;
  flex-direction: column;
  width: 94%;
}
.aboutContentHeading {
  margin-top: 40px;
  font-family: "Recoleta", sans-serif;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: normal;
  font-weight: 700;
  color: #ffffff;
}
.aboutContentText {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #d0d0d0;
  width: 93%;
}
.aboutDivider {
  width: 95%;
  height: 1px;
  background-color: #888888;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .aboutMainContainer {
    width: 95%;
    margin: 0 auto;
  }
  .aboutContentText {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .aboutMainContainer {
    width: 95%;
    margin: 0 auto;
  }
  .aboutContentHeading {
    width: 90%;
    margin: 20px auto;
  }
  .aboutContentText {
    width: 90%;
    margin: 20px auto;
  }
}
