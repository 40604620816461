.menuBtn {
  position: relative;
  background-image: url("../../assets/images/menu.png");
  width: 25px;
  height: 25px;
  background-size: cover;
  border: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}
.ant-drawer .ant-drawer-wrapper-body {
  background-color: black;
}
.ant-drawer-content-wrapper {
  width: 500px !important;
}
.sidebarImgStyling {
  background-size: cover;
  width: 65%;
}
.sidebarText1 {
  font-family: 'Recoleta', sans-serif;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: normal;
  font-weight: 500;
  color: #ffffff;
  margin: 20px 0 0 20px;
}
.sidebarText2 {
  font-family: 'Recoleta', sans-serif;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.25px;
  font-weight: 700;
  color: #888888;
  margin: 40px 0px 0px 20px;
}
.sidebarText3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0px 0px 20px;
}
.sidebarText4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 400;
  transition: color 0.3s ease;
  color: #ffffff;
  margin: 10px 0px 0px 20px;
}
.sidebarText4:hover {
  transition: color 0.3s ease;
  color: #ff7425;
  cursor: pointer;
}
.sidebarText6Container {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  margin: 20px 0px 0px 20px;
}
.sidebarText6 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 500;
  color: #ffffff;
}
.sidebarText6Input {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: normal;
  font-weight: 500;
  color: #ffffff;
  background-color: black;
  border: none;
  outline: none;
}
.sidebarText6Img {
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/email.png");
  background-size: cover;
  margin: 0 10px 0 0;
  transition: color 0.3s ease;
}
.sidebarText6Img:hover {
  background-image: url("../../assets/images/oemail.png");
}
.sidebarDivider {
  height: 1px;
  background-color: #888888;
  width: 90%;
  margin: 10px 0px 0px 20px;
}
.sidebarText7 {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: normal;
  font-weight: 400;
  color: #888888;
  margin: 0px 0px 0px 20px;
}
.sidebarLinks {
  display: flex;
  margin: 50px 0px 0px 20px;
  width: 30%;
  justify-content: space-between;
}
.sidebarLink {
  background-size: cover;
  width: 16px;
  height: 16px;
}
.sidebarLink1 {
  background-image: url('../../assets//images/instagram.png');
}
.sidebarLink1:hover {
  background-image: url('../../assets//images/oinstagram.png');
}
.sidebarLink2 {
  background-image: url('../../assets//images/twitter.png');
}
.sidebarLink2:hover {
  background-image: url('../../assets//images/otwitter.png');
}
.sidebarLink3 {
  background-image: url('../../assets//images/mail.png');
}
.sidebarLink3:hover {
  background-image: url('../../assets//images/omail.png');
}
.sidebarLink4 {
  background-image: url('../../assets//images/linkedin.png');
}
.sidebarLink4:hover {
  background-image: url('../../assets//images/olinkedin.png');
}
