.mainContainer {
  background-color: #161616;
  font-family: "Recoleta", sans-serif;
  display: flex;
  background-image: url("../../assets/videos/BackgroundVideo1.gif");
  background-size: cover;
  background-repeat: no-repeat;
}
.section1 {
  width: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* height: 65vh; */
  height: 600px;
  margin-top: 60px;
}
.section1Link {
  transform: rotate(270deg);
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.section1Link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.section2 {
  /* background-color: rebeccapurple; */
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.section2a {
  /* margin-top: 0px; */
}
.section2aPara {
  /* font-size: 130px; */
  font-size: 110px;
  font-weight: 700;
  color: white;
  /* background-color: orange; */
  margin: 80px 0 0 0;
  font-family: "Recoleta", sans-serif;
}
.section2b1 {
  display: flex;
}
.section2b2 {
  display: flex;
  align-items: center;
}

/* .section2bImgContainer {
  margin: 10px;
} */
.section2bImgStyling {
  width: 85px;
  height: 85px;
  margin: 15px 0 0 3vw;
}
.section2bPara1 {
  margin: 15px 0 0 20vw;
  /* font-size: 130px; */
  font-family: "Recoleta", sans-serif;
  font-size: 110px;
  font-weight: 700;
  color: #161616;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}
.section2bPara2 {
  margin: 15px 0 0 38vw;
  /* font-size: 130px; */
  font-family: "Recoleta", sans-serif;
  font-size: 110px;
  font-weight: 700;
  color: #161616;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}
.section2c {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.section2c1 {
}
.section2c1Para {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: white;
  line-height: 36px;
}
.section2c2 {
}

@media only screen and (max-width: 767px) {
  .mainContainer {
    flex-direction: row-reverse;
  }
  .section1 {
    padding-right: 10px;
    height: 40vh;
  }
  .section2 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 0 5vw;
  }
  .section2aPara {
    font-size: 8vw;
  }
  section2b1 {
  }
  section2b2 {
  }
  .section2bPara1 {
    font-size: 8vw;
    margin: 10px 0 0 18vw;
  }
  .section2bPara2 {
    font-size: 8vw;
    margin: 10px 0 0 36vw;
  }
  .section2bImgStyling {
    width: 10vw;
    height: 45px;
  }
  .section2c {
    flex-direction: column;
  }
  .section2c1Para {
    font-size: 3vw;
    line-height: 25px;
    font-weight: bold;
  }
  .section2c2 {
    margin: 20px 0 0 5vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mainContainer {
    flex-direction: row-reverse;
  }
  .section1 {
    padding-right: 10px;
    height: 55vh;
  }
  .section2 {
    width: 88vw;
    display: flex;
    flex-direction: column;
    padding: 0 5vw;
  }
  .section2aPara {
    font-size: 8vw;
  }
  /* .section2b {
    flex-direction: column;
    align-items: flex-start;
    background-color: rebeccapurple;
  } */
  .section2b1 {
  }
  .section2b2 {
    display: flex;
    align-items: center;
  }

  .section2bPara1 {
    font-size: 8vw;
    margin: 10px 0 0 18vw;
  }
  .section2bPara2 {
    font-size: 8vw;
    margin: 10px 0 0 36vw;
  }
  .section2bImgStyling {
    width: 65px;
    height: 65px;
  }
  .section2c {
    justify-content: space-between;
    align-items: center;
  }
  .section2c1 {
    width: 75%;
  }
  .section2c2 {
  }
  .section2c1Para {
    font-size: 2vw;
    width: 100%;
    line-height: 35px;
  }
}
