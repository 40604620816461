.createBlogContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createBlogInput {
  width: 50%;
  margin: 15px 0px;
}
.quill {
  width: 80vw;
  margin: 50px 0px;
}
