.blogMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.blogSection1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.blogSection1a {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.blogSection1aText1 {
  font-family: "Recoleta", sans-serif;
  font-size: 14px;
  line-height: 18.6667px;
  font-weight: 700;
  letter-spacing: 1.3px;
  color: #888888;
}

.blogSection1aText2 {
  font-family: "Recoleta", sans-serif;
  font-size: 38px;
  line-height: 49.4px;
  font-weight: 700;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 10px;
}

.blogSection1b {}

@media only screen and (max-width: 767px) {
  .blogSection1 {
    display: flex;
    width: 85%;
    flex-direction: column;
    align-items: flex-start;
  }

  .blogSection1b {
    margin: 6vw 0 0 0;
  }
}