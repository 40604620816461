.visionMainContainer {
  margin-top: 100px;
  display: flex;
}
.container1 {
  width: 50%;
}
.visionImg {
  width: 90%;
}
.container2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 3%;
}
.para1 {
  /* font-family: "DM Sans", sans-serif; */
  font-family: "Recoleta", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #888888;
  letter-spacing: 1.3px;
  margin-top: 30px;
}
.para2 {
  /* font-family: "DM Sans", sans-serif; */
  font-family: "Recoleta", sans-serif;
  font-size: 27px;
  font-weight: 700;
  color: white;
  width: 74%;
  letter-spacing: 1px;
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .visionMainContainer {
    flex-direction: column-reverse;
  }
  .container1 {
    width: 90%;
    margin: 20px auto;
  }
  .container2 {
    width: 100%;
    padding: 0 5vw;
  }
  .visionImg {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .visionMainContainer {
    flex-direction: column-reverse;
    margin-top: 100px;
  }
  .container1 {
    width: 80%;
    margin: 20px auto;
  }
  .container2 {
    width: 85%;
    padding: 0 5vw;
    margin: 0 auto;
  }
  .visionImg {
    width: 100%;
    margin: 0 auto;
  }

  .para2 {
    width: 60%;
  }
}
