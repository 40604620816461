
.servicesContainer {
  margin-top: 110px;
}
.heading {
  margin: 0 0 0 40px;
  /* font-family: "DM Sans", sans-serif; */
  font-family: "Recoleta", sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: normal;
  color: #161616;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnContainer {
  margin: 0 100px 0 0;
}
@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .heading {
    font-size: 10vw;
    letter-spacing: 4px;
  }
  .btnContainer {
    margin: 2vw 0 0 6vw;
  }
}
