.dashboardMainContainer {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;
}

.dashboardHeading {
  font-family: "Recoleta", sans-serif;
  color: #e66304;
  font-size: 6vw;
}
