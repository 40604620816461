.blogCardMainContainer {
  display: flex;
  width: 95%;
}
.blogCardImg {
  width: 100%;
}
.blogCardContainer {
  margin: 10px;
}
.blogCardSection2 {
  display: flex;
  margin-top: 20px;
}
.blogCardSection2Date {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1.4px;
  color: #888888;
}
.blogCardSection2Category {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1.4px;
  color: #888888;
  transition: 0.5s;
}
.blogCardSection2Category:hover {
  transition: 0.5s;
  color: white;
  cursor: pointer;
}
.blogCardSection3Title {
  margin-top: 10px;
  font-family: "Recoleta", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 41.99px;
  letter-spacing: normal;
  color: #ffffff;
  transition: 0.5s;
}
.blogCardSection3Title:hover {
  transition: 0.5s;
  text-decoration: underline;
  cursor: pointer;
}
.blogCardSection3Text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  color: #d0d0d0;
}
.blogCardSection3Btn {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1.4px;
  color: #06d900;
}

.blogCardPlusImage {
  width: 14px;
  height: 14px;
  background-image: url("../../assets//images/plus.png");
  background-size: cover;
  margin: 0 10px 0 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.blogCardSection4 {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.blogCardSection4:hover .blogCardPlusImage {
  opacity: 1;
  visibility: visible;
}
.blogCardSection4:hover {
  cursor: pointer;
}
