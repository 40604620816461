.footerMainContainer {
  background-color: #0e0e0e;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerContainerA {
  display: flex;
  padding-top: 100px;
  width: 95%;
}

.footerContainerA1 {
  display: flex;
  justify-content: center;
  margin: 0px 0 20px 0;
  width: 20%;
}
.footerContainerA1Img {
  width: 20vw;
  height: 60px;

}
.footerContainerA2 {
  margin: 20px;
  width: 25%;
}

.footerHeading {
  font-family: "Recoleta", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: normal;
  color: #ffffff;
}
.footerContainerA2OptionContainer {
  display: flex;
  margin-top: 30px;
}
.footerContainerA2OptionContainer:hover {
  cursor: pointer;
  .footerPlusStyling {
    opacity: 1;
    visibility: visible;
    width: 14px;
    height: 14px;
  }
  .footerText {
    color: #ff7425;
    transition: 0.5s;
  }
}
.footerPlusStyling {
  width: 0px;
  height: 0px;
  background-image: url("../../assets/images/oplus.png");
  background-size: cover;
  margin: 0 10px 0 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.footerText {
  font-family: "Monsterrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: normal;
  color: #ffffff;
}

.footerContainerA3Text1 {
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  color: #d0d0d0;
  margin-top: 20px;
  width: 22vw;
}
.footerContainerA3Text2 {
  font-family: "Monsterrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 33.75px;
  letter-spacing: normal;
  color: #ffffff;
  transition: color 0.3s ease;
  margin-top: 10px;
}
.footerContainerA3Text2:hover {
  cursor: pointer;
  color: #ff7425;
  transition: color 0.3s ease;
}

.footerContainerA3 {
  margin: 20px;
  width: 25%;
}
.footerContainerA4 {
  margin: 20px;
  width: 35%;
}
.footerContainerA41 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerInput {
  background-color: #0e0e0e;
  border: none;
  outline: none;
  font-family: "Monsterrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30.6px;
  letter-spacing: normal;
  color: #ffffff;
}
.footerContainerA4Text1 {
  font-family: "Monsterrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30.6px;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 20px;
}
.footerContainerA4Img {
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/email.png");
  background-size: cover;
  margin: 20px 10px 0 0;
  transition: color 0.3s ease;
}

.footerContainerA4Img:hover {
  background-image: url("../../assets/images/oemail.png");
}
.footerDivider {
  height: 1px;
  width: 95%;
  background-color: #888888;
  margin-top: 15px;
}

.footerContainerA4Text2 {
  font-family: "Monsterrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: normal;
  color: #888888;
  margin-top: 20px;
}
.footerContainerA4Img2Container {
  display: flex;
  justify-content: flex-start;
}
.footerContainerA4Img2 {
  width: 20px;
  height: 20px;
  background-size: cover;
  transition: color 0.3s ease;
  margin-top: 30px;
}
.footerContainerA4Img2:hover {
  cursor: pointer;
  transition: color 0.3s ease;
}
.instagram {
  background-image: url("../../assets/images/instagram.png");
}
.instagram:hover {
  background-image: url("../../assets/images/oinstagram.png");
}
.twitter {
  background-image: url("../../assets/images/twitter.png");
  margin-left: 20px;
}
.twitter:hover {
  background-image: url("../../assets/images/otwitter.png");
  margin-left: 20px;
}
.linkedin {
  background-image: url("../../assets/images/linkedin.png");
  margin-left: 20px;
}
.linkedin:hover {
  background-image: url("../../assets/images/olinkedin.png");
  margin-left: 20px;
}
.facebook {
  background-image: url("../../assets/images/facebook.png");
  margin-left: 20px;
}
.facebook:hover {
  background-image: url("../../assets/images/ofacebook.png");
  margin-left: 20px;
}
.footerDivider2 {
  height: 1px;
  width: 100%;
  background-color: #888888;
  margin-top: 100px;
}
.footerContainerB {
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 20px 0px;
}
.footerContainerB1 {
  width: 65%;
}
.footerContainerB1Text {
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  color: #888888;
  margin-left: 65px;
}

.footerContainerB2 {
  width: 35%;
  display: flex;
  justify-content: space-around;
}
.footerContainerB2Text {
  font-family: "Monsterrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  color: #888888;
  transition: color 0.3s ease;
}
.footerContainerB2Text:hover {
  color: #ff7425;
  cursor: pointer;
  transition: color 0.3s ease;
}
.footerContainerB3 {
}
.tabletFooter {
  display: flex;
  width: 50vw;
}
@media only screen and (max-width: 767px) {
  .footerMainContainer {
    flex-direction: column;
  }
  .footerContainerA {
    flex-direction: column;
  }
  .footerContainerA1 {
    width: 50%;
  }

  .footerContainerA1Img {
    width: 80vw;
    padding: 0;
  }
  .footerContainerA2 {
    width: 80vw;
  }
  .footerContainerA3 {
    width: 80vw;
  }
  .footerContainerA3Text1 {
    width: 100%;
  }
  .footerContainerA4 {
    width: 88%;
    margin: 0 auto;
  }
  .footerContainerB {
    flex-direction: column;
  }
  .footerContainerB1 {
    width: 70vw;
    margin: 0 auto;
  }
  .footerContainerB1Text {
    text-align: center;
    width: 75vw;
    margin: 0 auto;
    padding: 0;
  }
  .footerContainerB2 {
    width: 60vw;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 10px;
  }
  .tabletFooter {
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .footerMainContainer {
    flex-direction: column;
  }
  .footerContainerA {
    flex-direction: column;
  }
  .footerContainerA1 {
    width: 30%;
  }

  .footerContainerA1Img {
    width: 80vw;
    padding: 0;
  }
  .footerContainerA2 {
    width: 80vw;
  }
  .footerContainerA3 {
    width: 80vw;
  }
  .footerContainerA3Text1 {
    width: 100%;
  }
  .footerContainerA4 {
    width: 95%;
    margin: 0 auto;
  }
  .footerContainerB {
    flex-direction: column;
  }
  .footerContainerB1 {
    width: 70vw;
    margin: 0 auto;
  }
  .footerContainerB1Text {
    text-align: center;
    width: 75vw;
    margin: 0 auto;
    padding: 0;
  }
  .footerContainerB2 {
    width: 60vw;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 10px;
  }
  .tabletFooter {
    display: flex;
    width: 100%;
  }
}
