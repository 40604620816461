.ant-tabs-nav {
  width: 80%;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
  font-family: "Recoleta", sans-serif;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus {
  color: white !important;
  font-family: "Recoleta", sans-serif;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}
.ant-tabs .ant-tabs-tab-btn {
  color: #888888;
  font-family: "Recoleta", sans-serif;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}
.ant-tabs-tab-btn:focus {
  color: #888888 !important;
  font-family: "Recoleta", sans-serif;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}

.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #06d900;
  background: #e66304;
  pointer-events: none;
  width: 30px !important;
  height: 4px !important;
}
.introductionMainContainer {
}
.introductionPara {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* font-weight:bold; */
  color: #d0c6c0;
  margin-top: 10px;
  line-height: 30px;
  width: 90%;
}

.readMoreContainer {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.readMoreContainer:hover {
  cursor: pointer;

  .introductionImgContainer {
    background-image: url("../../assets/images/wplus.png");
  }
  .readMorePara {
    color: white;
  }
}
.introductionImgContainer {
  background-image: url("../../assets/images/oplus.png");
  width: 16px;
  height: 16px;
  background-size: cover;
}
.introductionImgStyling {
}
.readMorePara {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4;
  /* color: #06d900; */
  color: #e66304;
  margin-left: 10px;
}

.missionMainContainer {
}
.missionHeader {
  font-family: "Recoleta", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 36px;
  color: #ffffff;
}
.missionPara {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  /* font-weight: 400; */
  font-weight: bold;
  letter-spacing: normal;
  line-height: 30px;
  color: #d0d0d0;
  width: 85%;
}
.visionMainContainer {
}
.visionHeader {
  font-family: "Recoleta", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 36px;
  color: #ffffff;
}
.visionPara {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  color: #d0d0d0;
  width: 85%;
}

.tab-container {
  width: 80%;
  margin: 0 auto;
}

.tab-content {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.tab-content.active {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 767px) {
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 5vw;
  }
  .ant-tabs .ant-tabs-tab-btn {
    font-size: 5vw;
  }
  .introductionPara {
    margin: 10px auto;
  }
  .missionMainContainer {
    margin: 0 auto;
    width: 90%;
  }
  .missionPara {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .introductionPara {
    width: 100%;
  }
  .missionMainContainer {
    width: 90%;
  }
  .missionPara {
    width: 100%;
  }
}
