/* .homeMobileSliderContainer {
    display: none;
}
.homeTabletSliderContainer {
    display: none;
}
.homeSliderContainer{
  display: flex;
} */
@media only screen and (max-width: 767px) {
  .homeMobileSliderContainer {
    display: flex;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .homeTabletSliderContainer {
    display: flex;
    width: 100%;
  }
}
